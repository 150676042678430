<template>
  <div>
    <q-linear-progress v-if="loading" color="secondary" indeterminate />
    <q-page padding>
      <router-view />
    </q-page>
  </div>
</template>
<style scoped>
.v-progress-linear {
  margin-top: 0;
}
.v-pagination__item {
  border: 0;
}
</style>
<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('post', {
      loading: 'loading',
    })
  },
}
</script>

